import React from 'react';
import 'src/components/askGenie/homepageStyle.scss';
import chatbotLogo from 'src/images/askgenie_logo.png';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import documentTokens from '@amzn/meridian-tokens/base/icon/document';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import Heading from '@amzn/meridian/heading';
import Divider from '@amzn/meridian/divider';
import Card from '@amzn/meridian/card';
import { css } from '@emotion/css';
import { partnerLinks } from 'src/common/footer.const';
import Link from '@amzn/meridian/link';
import polevaultlogo from 'src/images/polevaultlogo.png';
import clipboard from '@amzn/meridian-tokens/base/icon/clipboard';

const fieldSetStyles = css({ border: 'none' });

const HomePageComponent = () => {
  const openOnboardingWiki = () => {
    window.open('https://w.amazon.com/bin/view/TransportationFrontline/AskGenie/Onboarding', '_blank', 'noreferrer');
  };
  const openAskgenie = () => {
    window.open('/askgenie', '_self');
  };

  const openToolPage = (link: string) => {
    window.open(link, '_blank', 'noreferrer');
  };

  return (
    <React.Fragment>
      <Row className="header-gradient-color" height="250px" widths={['40%', '50%']}>
        <Column>
          <div style={{ padding: '0 0 0 30px' }}>
            <Heading level={1} type="h600">
              AskGenie - AI Powered Service
            </Heading>
            <Text type="b200" color="secondary">
              Add Gen AI to your service
            </Text>
          </div>
        </Column>
        <Column alignmentHorizontal="end">
          <img src={chatbotLogo} alt="AskGenie Logo" />
        </Column>
      </Row>
      <Column alignmentHorizontal="center" alignmentVertical="center" spacingInset="400" className="homepage-container">
        <div className="homepage-description">
          <Text alignment="center" breakWord={true} type="b300">
            Unlock the true potential of your service by integrating with AskGenie. Our powerful Gen AI platform
            leverages advanced Retrieval-Augmented Generation (RAG) techniques to train highly specialized agents,
            tailored to your unique business requirements. Elevate your user experience, boost productivity, and drive
            innovation - all through the transformative power of AI. Take your service to new heights by partnering with
            AskGenie, the LLM-as-a-Service solution. Get started today and witness the extraordinary impact of Gen AI on
            your service.
          </Text>
        </div>

        <Row tag="fieldset" className={fieldSetStyles} spacing="500" spacingInset="400">
          <div className="card-styles">
            <Card spacingInset="none" width="300px">
              <div className="card-content-styles">
                <Column spacingInset="400" className="homepage-card">
                  <Column spacingInset="200 none " spacing="400">
                    <Heading level={3} type="h400" className="card-heading">
                      AskGenie - POC
                    </Heading>
                  </Column>
                  <Text breakWord={true} type="b200">
                    See the power of RAG in action, with this demo chatbot which has been trained with the wikis from
                    ATS Organisation about Peak Preparedness, CMBR calls and OE Meeting transcripts
                  </Text>
                </Column>
              </div>
              <Divider />
              <div className="clickable-wrapper" onClick={openAskgenie}>
                <Column spacingInset="400" className="homepage-card-action" alignmentVertical="center">
                  <Row alignmentHorizontal="center">
                    <Text className="card-text-color"> Try AskGenie Chatbot</Text>
                    <img src={chatbotLogo} alt="AskGenie" className="header-logo" />
                  </Row>
                </Column>
              </div>
            </Card>
          </div>
          <div className="card-styles">
            <Card spacingInset="none" width="300px">
              <div className="card-content-styles">
                <Column spacingInset="400" className="homepage-card">
                  <Column spacingInset="200 none">
                    <Heading level={3} type="h400" className="card-heading">
                      Tool Integration
                    </Heading>
                  </Column>
                  <Text type="b200">
                    Check how you can integrate with AskGenie and get Gen AI capabilities in your service/tool
                  </Text>
                </Column>
              </div>
              <Divider />
              <div className="clickable-wrapper" onClick={openOnboardingWiki}>
                <Column spacingInset="400" className="homepage-card-action" alignmentVertical="center">
                  <Row alignmentHorizontal="center">
                    <Text className="card-text-color">Onboarding Wiki</Text>
                    <Icon tokens={clipboard} className="wiki-icon" />
                  </Row>
                </Column>
              </div>
            </Card>
          </div>
        </Row>
        <div className="integrated-services">
          <Heading level={4} color="primary" alignment="center">
            Integrated Services
          </Heading>
          <div className="services-grid">
            {partnerLinks.Items.map((item, index) => (
              <div key={index} className="homepage-partners">
                <Link onClick={() => openToolPage(item.value)}>
                  <img className="logo" src={polevaultlogo} alt={`Partner ${index + 1}`} />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </Column>
    </React.Fragment>
  );
};

export default HomePageComponent;
