import React from 'react';
import { AuthenticationContext } from 'src/auth/authenticationContext';
import AppLayoutComponent from 'src/components/layouts/app-shell-layout/app-shell.layout';

export class App extends React.Component {
  render() {
    return (
      <AuthenticationContext>
        <AppLayoutComponent />
      </AuthenticationContext>
    );
  }
}

export default App;
