import React, { useState, useEffect, createContext, useContext } from 'react';
import { cognitoAuthenticate, getEmployeeAlias, getToken } from 'src/auth/cognito-auth';

export const defaultUserInfo = {
  loading: true,
  userAlias: 'Guest',
  authToken: '',
};

export const AuthContext = createContext(defaultUserInfo);

export const useAuthContext = () => useContext(AuthContext);

export const AuthenticationContext = (props: any) => {
  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  useEffect(() => {
    setUserInfo({ ...userInfo, loading: true });
    cognitoAuthenticate();
    setUserInfo({
      loading: false,
      userAlias: getEmployeeAlias(),
      authToken: getToken(),
    });
  }, []);

  return <AuthContext.Provider value={userInfo}>{props.children}</AuthContext.Provider>;
};
