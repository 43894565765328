import { METHOD_POST, apisURl } from '../../common/Constants';
import { isDevDomain } from '../utils';
import { customHeaders } from 'src/helpers/types/askGenie.types';

export const genericConversationAPI = async (payload: any, idToken: any) => {
  const url = apisURl['backendUrl'] + '/conversation/generic';
  try {
    let header: customHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    };
    if (isDevDomain()) {
      header = {
        ...header,
        devdomain: 'askgenie-integ.transportation.amazon.dev',
      };
    }
    const response = await fetch(url, {
      method: METHOD_POST,

      headers: header,
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
