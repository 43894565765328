export const DEFAULT_MAX_LOG_LINE_SIZE = 10000;

export const AMAZON_AWS = 'amazonaws';
export const API_IAM_ROLE_MAPPING: any = {
  PoleVault: 'arn:aws:iam::187183331844:role/PoleVault-UI-Access-Role',
};

export const STS_CREDS_EXPIRY_TIME_IN_MS = 3600 * 1000;
export const SERVICE_EXECUTE_API = 'execute-api';
export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const PATH_PROD = '/prod';
export const PATH_BETA = '/beta';

export const API_MAPPING: any = {
  PoleVault: '/conversation/polevault',
  Genric: '/conversation/generic',
};

export const apisURl: any = {
  backendUrl: 'https://api.askgenie.transportation.amazon.dev',
  webSocketUrl: '{{WebSocketUrl}}',
};
