import React, { memo } from 'react';
import './app-header.component.scss';
import { UserOptions } from './userOptions';
import Column from '@amzn/meridian/column';
import chatbotLogo from 'src/images/askgenie_logo.png';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
export interface TokenObject {
  [key: string]: string | number;
}
const AppHeaderComponent = () => {
  const customTokens: TokenObject = {
    mastheadBackgroundColor: '#045C69', // teal-600
    buttonForegroundColorIconLightBackdropDefault: '#fff',
  };
  return (
    <Row className="header" width="100%" widths="fill">
      <Column alignmentHorizontal="start">
        <Row>
          <img src={chatbotLogo} alt="..." className="header-logo" />
          <Text className="user-section" color="inverted">
            {' '}
            AskGenie
          </Text>
        </Row>
      </Column>
      <Column className="header-user" alignmentHorizontal="end">
        <UserOptions />
      </Column>
    </Row>
  );
};

export default memo(AppHeaderComponent);
