import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import AskGenieComponent from 'src/components/askGenie/askgenieComponent';

const AppContentComponent = () => {
  return (
    <Routes>
      <Route path="/askgenie" element={<AskGenieComponent />} />
    </Routes>
  );
};
export default memo(AppContentComponent);
