import decode from 'jwt-decode';
import { getItemFromCache, setItemInCache, toQueryString } from 'src/utils/utils';

export const TOKEN_KEY = 'amzn-cognito-token:ats-walle';
const REDIRECT_PATH = 'redirect-path';

export const getToken = () => {
  const authToken: any = getItemFromCache(TOKEN_KEY);
  if (authToken != null) {
    // exp from token is in seconds, but Date object uses milliseconds, so we need to convert
    const decodedToken = getDecodedToken(authToken.token);
    if (decodedToken.exp * 1000 > Date.now()) {
      return authToken.token;
    } else {
      // TODO: Need to check if we can silently refresh the token using fetch/axios on token expiry instead of redirecting
      const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
        detail: {
          customerId: decodedToken?.identities[0].userId,
        },
      });
      document.dispatchEvent(authenticatedUserEvent);
      cognitoAuthenticate(true);
    }
  } else {
    return null;
  }
};

export const getEmployeeAlias = (): string => getDecodedToken(getToken())?.identities[0].userId;

const setToken = (token: string) => {
  const decodedToken: Record<string, any> = decode(token);
  if (!decodedToken) throw new Error('Token could not be decoded');
  setItemInCache(TOKEN_KEY, { token: token });
};

const buildSSOUrl = () => {
  const cognitoDomain = 'https://ats-walle-chatbot-prod-domain.auth.us-east-1.amazoncognito.com';
  const clientId = '67njv57ta22519nef8brcb8lsu';
  const queryParams = {
    client_id: clientId,
    response_type: 'token',
    scope: 'openid',
    redirect_uri: 'https://askgenie.transportation.amazon.dev',
    // redirect_uri: document.location.href
  };
  return `${cognitoDomain}/authorize?${toQueryString(queryParams)}`;
};

const redirectToAuthUrl = () => {
  setItemInCache(REDIRECT_PATH, document.location.href);
  window.location.replace(buildSSOUrl());
};

export const getDecodedToken = (token: string) => {
  const decodedToken: Record<string, any> = decode(token);
  return decodedToken;
};

export const cognitoAuthenticate = (fetchNewToken?: boolean): any => {
  // try lookup from url
  const tokenParser = /id_token=([a-zA-Z0-9_\-.]+)/;
  const isTokenInUrl = () => tokenParser.test(window.location.hash);
  const getTokenParams = () => tokenParser.exec(window.location.hash);
  if (isTokenInUrl()) {
    const [, token, redirect] = getTokenParams()!;
    const decodedToken = getDecodedToken(token);
    if (!decodedToken) {
      redirectToAuthUrl();
      return false;
    }
    setToken(token);
    // These lines will ensure to redirect the user back the page they opened before Authentication
    const redirectPath: string = getItemFromCache(REDIRECT_PATH);
    window.location.replace(redirectPath);
    return true;
  }
  // Not calling getToken again if getToken is calling authenticate to get a new token since the old one had expired
  if (!fetchNewToken) {
    const cachedToken = getToken();
    const decodedToken = cachedToken && getDecodedToken(cachedToken);
    if (decodedToken) return true;
  }

  // redirect to auth
  redirectToAuthUrl();
  return false;
};
