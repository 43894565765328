import { isDevDomain } from '../utils';

export const webSocketInitializer = (onMessage: any, onError: any, webSocketURL: any) => {
  if (isDevDomain()) {
    webSocketURL = webSocketURL + '&devdomain=' + 'askgenie-integ.transportation.amazon.dev';
  }
  let reconnectAttempts = 0;
  let heartbeatIntervalId: number | null = null;
  const maxReconnectAttempts = 5;
  const heartbeatInterval = 30000; // 30 seconds
  let socket: WebSocket | null = null;
  const connect = () => {
    socket = new WebSocket(webSocketURL);
    socket.onopen = () => {
      console.log('WebSocket connected');
      reconnectAttempts = 0; // Reset reconnection attempts on successful connection

      // Start sending heartbeat messages
      startHeartbeat();
    };
    socket.onmessage = (event) => {
      const message = event.data;
      onMessage(message);
    };
    socket.onerror = async (error) => {
      console.error('WebSocket error:', error);
      onError(error);
      socket?.close();
    };
    socket.onclose = () => {
      console.log('WebSocket disconnected');
      stopHeartbeat();

      // Attempt to reconnect if the maximum attempts have not been reached
      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          reconnectAttempts++;
          connect();
        }, 1000 * reconnectAttempts); // Exponential backoff
      } else {
        console.error('Maximum reconnection attempts reached');
      }
    };
  };
  const startHeartbeat = () => {
    stopHeartbeat(); // Ensure no duplicate intervals are running
    heartbeatIntervalId = window.setInterval(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: 'heartbeat' }));
        console.log('Heartbeat sent');
      }
    }, heartbeatInterval);
  };

  const stopHeartbeat = () => {
    if (heartbeatIntervalId) {
      clearInterval(heartbeatIntervalId);
      heartbeatIntervalId = null;
    }
  };
  connect();

  return socket;
};
