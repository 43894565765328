import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import AppInitWrapper from './components/AppInitWrapper';
import './logger';

import '@amzn/katal-components/styles.css';
import './index.scss';
import 'src/components/askGenie/askgenie.scss';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<KatSpinner size="large" />}>
      <Router>
        <App />
      </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
