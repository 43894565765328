import React, { memo } from 'react';
import AppLayout from '@amzn/meridian/app-layout';
import AppFooterComponent from 'src/components/presentational/app-footer/app-footer.component';
import AppHeaderComponent from 'src/components/presentational/app-header/app-header.component';
import AppContentComponent from 'src/components/presentational/app-content/app-content.component';
import { useAuthContext } from 'src/auth/authenticationContext';
import Loader from '@amzn/meridian/loader';
import { useLocation } from 'react-router-dom';
import HomeContentComponent from 'src/components/presentational/app-content/home-content.component';

const AppLayoutComponent = (): JSX.Element => {
  const userInfo = useAuthContext();
  const location = useLocation();

  if (userInfo.loading) {
    return <Loader />;
  }

  // Check if we're on the main page
  const isMainPage = location.pathname === '/';
  if (isMainPage) {
    return (
      <>
        <AppLayout backgroundColor="alternateSecondary" footerComponent={AppFooterComponent}>
          <HomeContentComponent />
          <AppFooterComponent />
        </AppLayout>
      </>
    );
  }

  return userInfo.loading ? (
    <Loader />
  ) : (
    <AppLayout
      backgroundColor="alternateSecondary"
      headerComponent={AppHeaderComponent}
      footerComponent={AppFooterComponent}
    >
      <AppHeaderComponent />
      <AppContentComponent />
      <AppFooterComponent />
    </AppLayout>
  );
};
export default memo(AppLayoutComponent);
